export default {
  computed: {
    calcWidth(){ //this regulates width of sidebar
      if (window.innerWidth < 1400){
        return '660px';
      } else {
        return  '65%';
      }
    },
  }
};